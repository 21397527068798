import "./TotalEV.scss";
import { Count } from "../../utils/Helper";
import Indian_Flag from "../../Assets/Images/Indian_Flag.png";
import { useEffect, useState } from "react";
import axios from "axios";
import battery from "../../Assets/Images/battery.svg";
import { numberFormat } from "../../utils/Helper";
const EVCountData = {
  fourWheeler: 177644,
  twoWheeler: 2295130,
  date: "2022-09-05 01:00:21.697386",
};

const TotalEV = () => {
  const [evSalesCount, setEvSalesCount] = useState({
    twoWheeler: 0,
    fourWheeler: 0,
  });
  useEffect(() => {
    axios
      .get(process.env.REACT_APP_BACKEND_API + "evData")
      .then(({ data }) => {
        if (data?.success) {
          setEvSalesCount({
            twoWheeler: data?.data?.twoWheeler || 0,
            fourWheeler: data?.data?.fourWheeler || 0,
          });
        }
      })
      .catch((err) => {});
  }, []);
  return (
    <>
      <section className="" id="Home">
        <div className="TotalEV-Card">
          <div className="TotalEv-Wrap">
            <img src={Indian_Flag} style={{ height: "1.8rem" }} alt="" />
            <span className="Total-text">Total EV Sales in India</span>
          </div>
          <div className="sales">
            {/* <h1>{numberFormat(PermiumDetailsSummary(250000))}</h1> 2022-08-29 01:00:22.456437 */}
            {evSalesCount?.twoWheeler ? (
              <>
                <Count
                  label={"wer"}
                  number={evSalesCount.twoWheeler}
                  duration={1000}
                  style={{}}
                />
                <Count
                  label={"wer"}
                  number={evSalesCount.fourWheeler}
                  duration={1000}
                  style={{}}
                />
              </>
            ) : (
              ""
            )}
          </div>

          <div className="sales">
            <p className="wheeler">Two-Wheelers</p>
            <p className="wheeler">Four-Wheelers</p>
          </div>

          <div className="te-source">Source : vahan.parivahan.gov.in</div>
        </div>
      </section>
    </>
  );
};

export default TotalEV;
