import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Grid } from "@mui/material";
import pendingChallan_icon from "../../../Assets/Images/No-pending-challan.svg";
import checkCircle_icon from "../../../Assets/Images/check-circle.svg";
import CarLoader from "../../Loader/CarLoader";

const Tabs = ({ eChallandata, isLoading }) => {
  const navigate = useNavigate();
  const [toggleState, setToggleState] = useState("Not paid");

  const toggleTab = (index) => {
    setToggleState(index);
  };

  const getActiveClass = (index, className) =>
    toggleState === index ? className : "";

    const pandingCount1 = eChallandata?.data?.filter(function (obj) {
      return obj.challanStatus === "Not paid";
    })
    .map(function (obj) {
      return obj.challanStatus;
    });

    const paidCount1 = eChallandata?.data?.filter(function (obj) {
      return obj.challanStatus === "Paid";
    })
    .map(function (obj) {
      return obj.challanStatus;
    });

  return (
    <div>
      <div onClick={() => {navigate('/e-challan')}} className="check-no">Check another vehicle number?</div>
      <div className="container">
        <ul className="tab-list">
          <li
            className={`tabs ${getActiveClass("Not paid", "active-tabs")}`}
            onClick={() => toggleTab("Not paid")}
          >
            Pending Challans
          </li>
          <li
            className={`tabs ${getActiveClass("Paid", "active-tabs")}`}
            onClick={() => toggleTab("Paid")}
          >
            Paid challans
          </li>
        </ul>
        {
          isLoading ? <CarLoader/> :  <>
          <div className="content-container">
            {eChallandata?.data?.filter(function (obj) {
                return obj.challanStatus === "Not paid";
              })
              .map(function (obj) {
                return (
                  <>
                    {toggleState === "Not paid" ? (
                      <div
                        className={`content ${getActiveClass(
                          "Not paid",
                          "active-content"
                        )}`}
                      >
                        <div className="card-container">
                          <div className="title-head"> Reason</div>
                          <div className="sub-Data">
                          {obj.offenseDetails}
                          </div>

                          <Grid
                            container
                            alignItems="center"
                            className="reg-details"
                          >
                            <Grid item xs={6}>
                              <div className="title">Challan:</div>
                              <div className="sub-Data">₹ {obj.amount}</div>
                            </Grid>
                            <Grid item xs={6}>
                              <div className="title">Challan No:</div>
                              <div className="sub-Data">{obj.challanNumber}</div>
                            </Grid>
                          </Grid>
                          <Grid
                            container
                            alignItems="center"
                            className="reg-details"
                          >
                            <Grid item xs={6}>
                              <div className="title">Location:</div>
                              <div className="sub-Data">{obj.state}</div>
                            </Grid>
                            <Grid item xs={6}>
                              <div className="title">Violation Date:</div>
                              <div className="sub-Data">{obj.challanDate}</div>
                            </Grid>
                          </Grid>
                          <Grid
                            container
                            alignItems="center"
                            className="reg-details"
                          >
                            <Grid item xs={10}>
                              <div className="title">Violater:</div>
                              <div className="sub-Data">{obj.accusedName}</div>
                            </Grid>
                          </Grid>
                        </div>
                      </div>
                    ) : null}
                  </>
                );
              })}


            {eChallandata?.data?.filter(function (obj) {
                return obj.challanStatus === "Paid";
              })
              .map(function (obj) {
                return (
                  <>
                    {toggleState === "Paid" ? (
                      <div
                        className={`content ${getActiveClass(
                          "Paid",
                          "active-content"
                        )}`}
                      >
                        <div className="card-container">
                          <div className="title-head"> Reason</div>
                          <div className="sub-Data">  {obj.offenseDetails}</div>

                          <Grid
                            container
                            alignItems="center"
                            className="reg-details"
                          >
                            <Grid item xs={6}>
                              <div className="title">Challan:</div>
                              <div className="sub-Data">
                                ₹ {obj.amount}
                                <spam>
                                  <img
                                    src={checkCircle_icon}
                                    alt="paidIcon"
                                    className="paidIcon"
                                  />
                                </spam>
                              </div>
                            </Grid>
                            <Grid item xs={6}>
                              <div className="title">Challan No:</div>
                              <div className="sub-Data">{obj.challanNumber}</div>
                            </Grid>
                          </Grid>
                          <Grid
                            container
                            alignItems="center"
                            className="reg-details"
                          >
                            <Grid item xs={6}>
                              <div className="title">Location:</div>
                              <div className="sub-Data">{obj.state}</div>
                            </Grid>
                            <Grid item xs={6}>
                              <div className="title">Violation Date:</div>
                              <div className="sub-Data">{obj.challanDate}</div>
                            </Grid>
                          </Grid>
                          <Grid
                            container
                            alignItems="center"
                            className="reg-details"
                          >
                            <Grid item xs={10}>
                              <div className="title">Violater:</div>
                              <div className="sub-Data">{obj.accusedName}</div>
                            </Grid>
                          </Grid>
                        </div>
                      </div>
                    ) : null}
                  </>
                );
              })}
          </div>

          {pandingCount1?.length === 0 ? (
            <div
              className={`content ${getActiveClass("Not paid", "active-content")}`}
            >
              <div className="no-panding">
                <img src={pendingChallan_icon} alt="Charge" className="img" />
                <div> No pending challans for your vehicle.</div>
              </div>
            </div>
          ) : null}

        {paidCount1?.length === 0 ? (
            <div
              className={`content ${getActiveClass("Paid", "active-content")}`}
            >
              <div className="no-panding">
                <img src={pendingChallan_icon} alt="Charge" className="img" />
                <div> No Paid challans.</div>
              </div>
            </div>
          ) : null}
          </>
        }

      </div>
    </div>
  );
};

export default Tabs;
