import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import Loader from "../../../Components/Loader/CarLoader";
import axios from "axios";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import "./EChallanStatus.scss";
import rtoVehicle from "../../../Assets/Images/rtocar.png";
import ResuableHeader from "../../ResuableHeader/ResuableHeader";
import { Grid } from "@mui/material";
import Tabs from "../Tabs/Tabs";
import StickyHeader from "../../UI/StickyHeader";
import backArrow from "../../../Assets/Images/arrow-back.svg";
import { decrypt } from "../../../service/encryption";

const EChallanStatus = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [vehicleInfo, setVehicleInfo] = useState([]);
  const [eChallandata, setEChallanData] = useState([]);
  const [statusCode, setStatusCode] = useState(null);
  const [loaderValue, setLoaderValue] = useState(false);
  const [vehicleLoaderValue, setVehicleLoaderValue] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const authToken = useSelector((state) => state.auth.token);

  const getVehicle = async (id, token) => {
    const url = `${process.env.REACT_APP_BACKEND_API}vehicle-detail/info`;
    try {
      const result = await axios.post(
        url,
        { vehicleNumber: state?.regNo },
        { headers: { token: token } }
      );
      const decryptData = JSON.parse(decrypt(result?.data?.data));
      setVehicleInfo({ data: decryptData });
      setStatusCode(200);
    } catch (err) {
      setStatusCode(err.response.status);
    }
  };

  const geteChallan = async (id, token) => {
    const url = `${process.env.REACT_APP_BACKEND_API}e-challan/info`;
    try {
      setIsLoading(true);
      const { data } = await axios.post(
        url,
        { vehicleNumber: state?.regNo },
        { headers: { token: token } }
      );
      setEChallanData(data);
      setStatusCode(200);
    } catch (err) {
      setStatusCode(err.response.status);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setVehicleLoaderValue(true);
    getVehicle(state?.regNo, authToken);
  }, [authToken, state?.regNo]);

  useEffect(() => {
    setLoaderValue(true);
    geteChallan(state?.regNo, authToken);
  }, [authToken, state?.regNo]);

  useEffect(() => {
    if (loaderValue && statusCode !== null) {
      setLoaderValue(false);
    }
  }, [statusCode, loaderValue]);

  useEffect(() => {
    if (vehicleLoaderValue && statusCode !== null) {
      setVehicleLoaderValue(false);
    }
  }, [statusCode, vehicleLoaderValue]);
  return (
    <div>
      {/* <ResuableHeader
        header="e-Challan"
        url="/dashboard"
        bgc="#115173"
        headerDesc="Find the charging stations on the way"
      /> */}
      <StickyHeader>
        <section className="header-container">
          <div
            className="back-arrow"
            onClick={() => {
              navigate("/e-challan");
            }}
          >
            <img src={backArrow} alt="Back-Icon" />
          </div>
          <div className="heading-text">
            <p>e-Challan</p>
          </div>
        </section>
      </StickyHeader>
      <div className="chek-vehicle-container status">
        {loaderValue && <Loader />}

        {!loaderValue && statusCode !== 200 && statusCode !== null && (
          <div className="errormessage">Vehicle Number not found</div>
        )}

        {!vehicleLoaderValue && statusCode === 200 && vehicleInfo && (
          <div className="card-container">
            <Grid container alignItems="center">
              <Grid item xs={10}>
                <div className="vehile_Title">
                  {vehicleInfo.data.vehicleManufacturerName || "NA"}
                </div>
                <div className="subTitle">{vehicleInfo.data.regNo || "NA"}</div>
              </Grid>
            </Grid>

            <div className="varient">
              <Grid container alignItems="center" spacing={1}>
                <Grid item xs={9} sm={9}>
                  <div className="title-div">Variant:</div>
                  <div className="sub-subTitle">
                    {vehicleInfo.data.model || "NA"}
                  </div>
                </Grid>
                <Grid item xs={3} sm={3}>
                  <div className="title-div">Reg year:</div>
                  <div className="sub-subTitle">
                    {moment(vehicleInfo.data.regDate, "DD/MM/YYYY").format(
                      "YYYY"
                    )}
                  </div>
                </Grid>
              </Grid>
            </div>
          </div>
        )}

        {!loaderValue && statusCode === 200 && vehicleInfo && (
          <Tabs eChallandata={eChallandata} isLoading={isLoading} />
        )}
      </div>
    </div>
  );
};

export default EChallanStatus;
