import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import axios from "axios";
import Loader from "../../../Components/Loader/CarLoader";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import "./VehicleRegistrationDetails.scss";
import backArrow from "../../../Assets/Images/arrow-back.svg";
import ResuableHeader from "../../ResuableHeader/ResuableHeader";
import { HandleScrollToTop } from "../../../APIs/CommonLogics";
import { geteVehicleInfo } from "../../../slice/vehicleInfoSlice";
import StickyHeader from "../../UI/StickyHeader";
import { Grid } from "@mui/material";
import { decrypt } from "../../../service/encryption";

const VehicleRegistrationDetails = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [vehicleInfo, setVehicleInfo] = useState([]);
  const [statusCode, setStatusCode] = useState(null);
  const [loaderValue, setLoaderValue] = useState(false);

  const authToken = useSelector((state) => state.auth.token);

  const getVehicle = async (id, token) => {
    const url = `${process.env.REACT_APP_BACKEND_API}vehicle-detail/info`;
    try {
      // for Dev
      const result = await axios.post(
        url,
        { vehicleNumber: state?.regNo || "" },
        { headers: { token: token } }
      );
      // for UAT
      // const {data} = await axios.get(`https://ev-uat.hdfcergo.com/ev/vehicle-detail/info?vehicleNumber=${id}`)
      const decryptData = JSON.parse(decrypt(result?.data?.data));
      setVehicleInfo({ data: decryptData });
      setStatusCode(200);
    } catch (err) {
      setStatusCode(err.response.status || 500);
    }
  };

  useEffect(() => {
    setLoaderValue(true);
    getVehicle(state?.regNo, authToken);
  }, [state?.regNo, authToken]);

  useEffect(() => {
    if (loaderValue && statusCode !== null) {
      setLoaderValue(false);
    }
  }, [statusCode, loaderValue]);

  return (
    <div className="vehicle-wrapper">
      {/* <ResuableHeader
      header="Check Vehicle Info"
      url="/dashboard"
      bgc="#115173"
      headerDesc="Find the charging stations on the way"
    /> */}

      <StickyHeader>
        <section
          className="header-container"
          style={{ padding: "20px 10px 20px 10px" }}
        >
          <div
            className="back-arrow"
            onClick={() => {
              navigate("/checkvehicleinfo");
            }}
          >
            <img src={backArrow} alt="Back-Icon" />
          </div>
          <div className="heading-text">
            <p>Check Vehicle Info</p>
          </div>
        </section>
      </StickyHeader>

      {loaderValue && <Loader />}

      {!loaderValue && statusCode !== 200 && statusCode !== null && (
        <div className="errormessage">Vehicle Number not found</div>
      )}

      {!loaderValue && statusCode === 200 && vehicleInfo && (
        <div className="chek-vehicle-container">
          <div className="card-container">
            <Grid container alignItems="center">
              <Grid item xs={10}>
                <div className="vehile_Title">
                  {vehicleInfo.data.vehicleManufacturerName}
                </div>
                <div className="subTitle">{vehicleInfo.data.regNo}</div>
              </Grid>
            </Grid>

            <div className="varient">
              <Grid container alignItems="center" spacing={1}>
                <Grid item xs={9} sm={9}>
                  <div className="title-div">Variant:</div>
                  <div className="sub-subTitle">{vehicleInfo.data.model}</div>
                </Grid>
                <Grid item xs={3} sm={3}>
                  <div className="title-div">Reg year:</div>
                  <div className="sub-subTitle">
                    {moment(vehicleInfo.data.regDate, "DD/MM/YYYY").format(
                      "YYYY"
                    )}
                  </div>
                </Grid>
              </Grid>
            </div>
          </div>

          <div
            onClick={() => {
              navigate("/checkvehicleinfo");
            }}
            className="check-no"
          >
            Check another vehicle number?
          </div>
          <div className="card-container">
            <div className="reg-title"> Registration details </div>
            <Grid container alignItems="center" className="reg-details">
              <Grid item xs={7}>
                <div className="left-title">Fuel type</div>
              </Grid>
              <Grid item xs={5} className="align_-div">
                <div className="lineBreack">{vehicleInfo.data.type}</div>
              </Grid>
            </Grid>
            <div className="border-div"></div>
            <Grid container alignItems="center" className="reg-details">
              <Grid item xs={7}>
                <div className="left-title">Owner name</div>
              </Grid>
              <Grid item xs={5} className="align_-div">
                <div className="lineBreack">{vehicleInfo.data.owner}</div>
              </Grid>
            </Grid>
            <div className="border-div"></div>
            <Grid container alignItems="center" className="reg-details">
              <Grid item xs={7}>
                <div className="left-title">Registration date</div>
              </Grid>
              <Grid item xs={5} className="align_-div">
                <div className="lineBreack">{vehicleInfo.data.regDate}</div>
              </Grid>
            </Grid>
            <div className="border-div"></div>
            <Grid container alignItems="center" className="reg-details">
              <Grid item xs={7}>
                <div className="left-title">Insurer</div>
              </Grid>
              <Grid item xs={5} className="align_-div">
                <div className="lineBreack">
                  {vehicleInfo.data.vehicleInsuranceCompanyName}
                </div>
              </Grid>
            </Grid>
            <div className="border-div"></div>
            <Grid container alignItems="center" className="reg-details">
              <Grid item xs={7}>
                <div className="left-title">Engine number</div>
              </Grid>
              <Grid item xs={5} className="align_-div">
                <div className="lineBreack">{vehicleInfo.data.engine}</div>
              </Grid>
            </Grid>
            <div className="border-div"></div>
            <Grid container alignItems="center" className="reg-details">
              <Grid item xs={7}>
                <div className="left-title">Chassis number</div>
              </Grid>
              <Grid item xs={5} className="align_-div">
                <div className="lineBreack">{vehicleInfo.data.chassis}</div>
              </Grid>
            </Grid>
            <div className="border-div"></div>
            <Grid container alignItems="center" className="reg-details">
              <Grid item xs={7}>
                <div className="left-title">Vehicle type</div>
              </Grid>
              <Grid item xs={5} className="align_-div">
                <div className="lineBreack">
                  {vehicleInfo.data.vehicleCategory}
                </div>
              </Grid>
            </Grid>
            {/* <div className='border-div'></div>
         <Grid container  alignItems="center" className='reg-details'>
           <Grid item xs={7} >
            <div className="left-title">Previous policy expired</div>
           </Grid>
           <Grid item xs={5} className="align_-div">
           <div className="lineBreack">{moment(vehicleInfo.data.vehicleInsuranceUpto, 'DD/MM/YYYY').isSameOrBefore(moment()) ? 'Yes' : 'No'}</div>
           </Grid>
         </Grid> */}
            <div className="border-div"></div>
            <Grid container alignItems="center" className="reg-details">
              <Grid item xs={7}>
                <div className="left-title">Previous policy expired date</div>
              </Grid>
              <Grid item xs={5} className="align_-div">
                <div className="lineBreack">
                  {vehicleInfo.data.vehicleInsuranceUpto}
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
      )}
    </div>
  );
};

export default VehicleRegistrationDetails;
